import { Dialog, Alert } from '@mui/material';
import React from 'react';

const PortraitAlert = ({ portrait }) => {
  return (
    <Dialog open={portrait}>
      <Alert severity="error">Please use Landscape mode</Alert>
    </Dialog>
  )

};

export default PortraitAlert;
