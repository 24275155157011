const handleImageSnapshot = (canvas, background_url) => {

  const main_canvas = canvas.current.getContext('2d');
  const aspectRatio = 1920/1080;
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const windowAspectRatio = windowWidth / windowHeight;

  let imageWidth, imageHeight
  if (windowAspectRatio < aspectRatio) {

    imageWidth = windowWidth;
    imageHeight = windowWidth / aspectRatio;

  } else {
    imageWidth = windowHeight * aspectRatio;
    imageHeight = windowHeight;
  }

  // scale the canvas accordingly
  var image = new Image();
  image.src = background_url
  image.onload = function () {
    main_canvas.drawImage(image, 0, 0, imageWidth, imageHeight);
  }
};

export default handleImageSnapshot;

// const handleImageSnapshot = (canvas, background_url, xlDown) => {
//   //console.log('set canvas', background_url)
//   const main_canvas = canvas.current.getContext('2d');
//   // scale the canvas accordingly
//   var image = new Image();
//   image.src = background_url
//   image.onload = function () {
//     if (xlDown) {
//       //main_canvas.drawImage(image, 0, 0, 1024, 1024);
//       main_canvas.drawImage(image, 0, 0, 1920, 1080);
//     } else {
//       main_canvas.drawImage(image, 0, 0, 1920, 1080);
//     }
//   }
// };

// export default handleImageSnapshot;
