import React, { useReducer, createContext } from 'react';
import { AreaReducer } from './AreaReducers';

export const AreaContext = createContext();

export const AreaProvider = ({ children }) => {
  const [transition, dispatchTransition] = useReducer(AreaReducer, {
    play: false,
    stop: true,
    to: '/',
    url: null
  });
  const [currentArea, dispatchCurrentArea] = useReducer(AreaReducer, {});
  const [allAreas, dispatchAllAreas] = useReducer(AreaReducer, null);
  const [destination, dispatchDestination] = useReducer(AreaReducer, null);

  const contextValues = {
    transition,
    dispatchTransition,
    allAreas,
    dispatchAllAreas,
    currentArea,
    dispatchCurrentArea,
    destination,
    dispatchDestination
  };
  return (
    <AreaContext.Provider value={contextValues}>
      {children}
    </AreaContext.Provider>
  );
};
