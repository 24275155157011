import React, { forwardRef, useContext, useEffect, useState, useRef } from 'react';
import SplitBackground from './SplitBackground';
import { AreaContext } from '../store/AreaContext';
import { useHistory } from 'react-router-dom'
import { motion } from 'framer-motion';
import { PLAY, SAVE_DESTINATION, STOP } from '../store/AreaReducers';
import handleFirstSnapshot from '../functions/handleFirstSnapshot';
import handleImageSnapshot from '../functions/handleImageSnapshot';
import BackgroundCanvas from './canvas/BackgroundCanvas';
import VideoCanvas from './canvas/VideoCanvas';

const AreaBackground = forwardRef(({ area, video_ref, handleVideoSnapshot, canvas }, ref) => {

  //const xlDown = useMediaQuery((theme) => theme.breakpoints.down('xl'));

  const background_canvas_ref = useRef(null);
  const history = useHistory()
  //console.log(canvas_ref)

  const {
    transition,
    dispatchTransition,
    destination,
    dispatchDestination,
    currentArea
  } = useContext(AreaContext);

  //console.log(transition)

  //const [background_url, setBackground_url] = useState(area?.attributes?.background?.data?.attributes?.url);
  const [background_url, setBackground_url] = useState(`${process.env.REACT_APP_API}${area?.attributes?.background?.data?.attributes?.url}`);
  //console.log(background_url)

  const [isVideo, setIsVideo] = useState(
    area?.attributes?.background?.data?.attributes?.mime.includes('video')
  );

  useEffect(() => {

    //console.log(transition)
    if (transition.play && transition.url) {
      //only play if there is a video url
      handleImageSnapshot(canvas, background_url);
      //setBackground_url(transition.url);
      setBackground_url(`${process.env.REACT_APP_API}${transition.url}`);
      setIsVideo(true);
      return;
    } else if (transition.play && !transition.url) {
      //if no image set canvas to current background image and fade to next area
      handleImageSnapshot(canvas, background_url);
      setTimeout(() => {
        dispatchDestination({ type: SAVE_DESTINATION, payload: null });
        setBackground_url();
        dispatchTransition({
          type: STOP
        });
        history.push(destination.to);
      }, [200]) // to stop flickering
    }
    setIsVideo(area?.attributes?.background?.data?.attributes?.mime.includes('video'));
  }, [transition, area?.attributes?.background?.data?.attributes]);

  //console.log(currentArea)

  const handleVideoEnd = e => {
    //sets main canvas so there is not a flicker

    handleVideoSnapshot();

    setTimeout(() => {
      if (destination) {
        if (transition.to === destination.to) {

          dispatchDestination({ type: SAVE_DESTINATION, payload: null });
          setBackground_url();
          dispatchTransition({
            type: STOP
          });
          history.push(destination.to);
          return;
        }
        if (
          destination.to.split('/').length > currentArea.route.split('/').length
        ) {
          dispatchTransition({
            type: PLAY,
            payload: {
              url: destination.transition_url,
              to: destination.to
            }
          });
          history.push(destination.to);
        } else {
          if (currentArea.transition_out) {
            dispatchTransition({
              type: PLAY,
              payload: {
                url: currentArea.transition_out.url,
                to: currentArea.parent_area.route
              }
            });
            history.push(currentArea.parent_area.route);
            return;
          }
        }
      }
    }, [200]) // to stop flickering
  };

  // //draw background on first load
  useEffect(() => {
    //console.log(background_url)
    // if (background_url && !isVideo && canvas) {
    //   const inner_canvas = canvas_ref.current.getContext('2d');
    //   //console.log(canvas)
    //   var image = new Image();
    //   image.src = background_url
    //   image.onload = function () {
    //     if (xlDown) {
    //       inner_canvas.drawImage(image, 0, 0, 1024, 1024);
    //     } else {
    //       inner_canvas.drawImage(image, 0, 0, 1920, 1080);
    //     }
    //   }
    //   if (firstLoad) {
    handleFirstSnapshot(canvas, background_url);

    // }
    //}
  }, [])

  // SPLIT BACKGROUND
  if (area?.split_screen)
    return (
      <SplitBackground
        area={area}
        handleVideoEnd={handleVideoEnd}
        background_url={background_url}
        isVideo={isVideo}
        ref={ref}
      />
    );

  // NORMAL FULL WIDTH
  return (
    <>
      {isVideo ? (
        <>
          {transition.play ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              style={{
                display: 'grid',
                alignItems: 'center',
                width: '100vw',
                height: '100vh',
                justifyContent: 'center',
                alignContent: 'center',
                position: "fixed",
                top : 0,
                left: 0
              }}
            >
              <VideoCanvas
                background_url={background_url}
                onVideoEnd={handleVideoEnd}
                setBackground_url={setBackground_url}
                video_ref={video_ref}
              />
            </motion.div>

          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              style={{
                display: 'grid',
                alignItems: 'center',
                width: '100vw',
                height: '100vh',
                justifyContent: 'center',
                alignContent: 'center',
                position: "absolute"
                //height: "100%"
              }}
            >
              <VideoCanvas
                background_url={background_url}
                onVideoEnd={handleVideoEnd}
                video_ref={video_ref}
                //type={area?.attributes?.background?.data?.attributes?.mime} 
              />
              {/* <video
                muted
                autoPlay
                loop
                //width={xlDown ? "1920" : "1920"}
                //height={xlDown ? "1080" : "1080"}
                // width={"100%"}
                // height={"100%"}
                // style={{
                //   // width: xlDown ? "1024" : "100%",
                //   // height: xlDown ? "1024" : "100%"
                //   width: "100%",
                //   //height: "100%"
                // }}
                onTimeUpdate={e => {
                  if (destination) {
                    if (
                      Math.round(e.target.currentTime) ===
                      Math.round(e.target.duration)
                    ) {
                      handleVideoEnd();
                    }
                  }
                }}
                //className={classes.video}
                // onLoadedDataCapture={handleVideoSnapshot}
                ref={video_ref}
                id="background_video"
              >
                <source src={background_url} type={area?.attributes?.background?.data?.attributes?.mime} />
              </video> */}
            </motion.div>
          )}
        </>
      ) : null}

      <BackgroundCanvas canvas_ref={background_canvas_ref} background_url={background_url} />

      {/* <canvas
        ref={canvas_ref}
        //className={classes.canvas_background}
        id="myCanvas"
        // width="1920"
        // height="1080"
        width={xlDown ? "1920" : "1920"}
        height={xlDown ? "1080" : "1080"}
        style={{
          // width: xlDown ? "1024" : "100%",
          // height: xlDown ? "1024" : "100%"
          maxWidth: "100vw",
          minWidth: "100vw",
          //height: "100%"
        }}
      /> */}

    </>
  );
});

export default AreaBackground;
