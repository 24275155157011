import React, { forwardRef, useContext } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AreaContext } from '../store/AreaContext';
import { motion } from 'framer-motion';
import { PLAY, SAVE_DESTINATION } from '../store/AreaReducers';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    // position: 'absolute'
  },
  container: {
    width: '100%',
    paddingTop: '56.25%',
    height: '0px',
    position: 'relative'
  },
  sub_area: {
    height: '100%',
    width: '100vw',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  video: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0'
  }
}));

const SplitBackground = forwardRef(
  ({ area, background_url, handleVideoEnd, isVideo }, ref) => {
    const history = useHistory();
    const classes = useStyles();
    const { dispatchTransition, dispatchDestination } = useContext(AreaContext);

    const handleSubAreaClick = sub_area => {
      // Save the destination area
      console.log(sub_area)

      dispatchDestination({
        type: SAVE_DESTINATION,
        payload: {
          to: `${history.location.pathname}/${sub_area.slug}`,
          transition_url: sub_area?.transition_in?.url ? sub_area.transition_in.url : null
        }
      });

      dispatchTransition({
        type: PLAY,
        payload: {
          url: sub_area?.transition_in?.url ? sub_area.transition_in.url : null,
          to: `${history.location.pathname}/${sub_area.slug}`
        }
      });
    };

    if (isVideo)
      return (
        <div className={classes.container} id="video">
          <motion.video
            muted
            autoPlay
            playsInline
            ref={ref}
            onEnded={handleVideoEnd}
            className={classes.video}
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
          >
            <source src={background_url} type={area.background?.mime} />
          </motion.video>
        </div>
    );

    return (
      <Grid container className={classes.root}>

        {console.log(area)}
        {area.split_screen.map(sub_area => {
          //console.log(sub_area)
          let image_url
          // if(sub_area.background){
          //   image_url = `${process.env.REACT_APP_API}${sub_area.background.url}`;
          // }
          if(sub_area.area.background){
            image_url = `${process.env.REACT_APP_API}${sub_area.area.background.url}`;
          }

          return (
            <Grid item xs={6} key={sub_area.id}>
              <img
                key={sub_area.id}
                src={image_url ? image_url : null}
                alt={sub_area.alternativeText}
                onClick={() => handleSubAreaClick(sub_area)}
                className={classes.sub_area}
                onError={
                  console.log('no image')
                }
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }
);

export default SplitBackground;
