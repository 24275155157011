import { Box, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { FilterContext } from "../context/FilterContext";
import { faSlidersH, faTimes } from "@fortawesome/pro-regular-svg-icons";
import LabelledButton from "./LabelledButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/pro-regular-svg-icons";
import { isMobile } from 'react-device-detect';

export default function FilterBar(){

    const filters=useContext(FilterContext);

    const [open, setOpen]=useState(false);

    const catColors=[
        //Fungicide
        'rgb(18, 104, 179)', 
        //Herbicide
        'rgb(114, 191, 68)', 
        //Insecticide
        'rgb(237, 26, 59)'
    ];

    return(
        <>
            <Box
                sx={{
                    zIndex:99999,
                    position:'absolute',
                    top:'1rem',
                    right:'1rem'
                }}
            >
                <LabelledButton
                    label='FILTERS'
                    icon={open? faTimes : faSlidersH}
                    background='rgb(0, 97, 117)'
                    color='#fff'
                    onClick={()=>setOpen(!open)}
                />
            </Box>
            <Box
                sx={{
                    zIndex:9999,
                    position:'absolute',
                    top:0,
                    right: open? 0 : '-300px',
                    transition:'right 0.25s',
                    height:'100%',
                    width:'300px',
                    background: isMobile? 'rgba(0,0,0,0.85)' : 'rgba(0,0,0,0.65)',
                    color:'#fff',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent: isMobile? 'unset' : 'center',
                    p:'2rem',
                    overflow:'scroll'
                }}
            >
                <Typography
                    sx={{
                        fontWeight:600,
                        fontSize: isMobile? '0.8rem' : '1rem'
                    }}
                >
                    FILTER BY
                </Typography>
                {filters.categories.map((category, i)=>{
                    //console.log(category)
                    return(
                        <FilterBtn
                            color={catColors[i]}
                            label={category.attributes.name}
                            onClick={()=>filters.setCategory(filters.category===category.id? null : category.id)}
                            active={filters.category===category.id}
                            key={i}
                        />
                    );
                })}
                <FilterBtn
                    color='rgb(120, 146, 105)'
                    label='Biorational'
                    onClick={()=>filters.setBioRational(!filters.bioRational)}
                    active={filters.bioRational}
                />
                <FilterBtn
                    label='All products'
                    onClick={()=>filters.setIsNew(false)}
                    active={!filters.isNew}
                />
                <FilterBtn
                    color='rgb(250, 166, 26)'
                    label='New products'
                    onClick={()=>filters.setIsNew(true)}
                    active={filters.isNew}
                    icon={faBullhorn}
                />
            </Box>
        </>
    );
}

function FilterBtn({ color='white', label, onClick, active, icon, img }){

    return(
        <Box
            onClick={onClick}
            sx={{
                m:'1rem 0',
                display:'flex',
                alignItems:'center',
                cursor:'pointer',
                opacity: active? 1 : 0.5
            }}
        >
            {img?
                <img
                    
                />
            :
                <Box
                    sx={{
                        width: isMobile? '2rem' : '2.5rem',
                        minWidth: isMobile? '2rem' : '2.5rem',
                        height: isMobile? '2rem' : '2.5rem',
                        background:color,
                        borderRadius:'50%',
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'center'
                    }}
                >
                    {icon &&
                        <FontAwesomeIcon
                            icon={icon}
                            style={{
                                fontSize: isMobile? '1rem' : '1.25rem'
                            }}
                        />
                    }
                </Box>
            }
            <Typography
                sx={{
                    ml:'1rem',
                    fontWeight:600,
                    fontSize: isMobile? '0.8rem' : '1rem'
                }}
            >
                {label}
            </Typography>
        </Box>
    );
}