import { faBooks } from '@fortawesome/pro-regular-svg-icons';
import { Box } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import ResourceDialog from './Resources/ResourceDialog';
import LabelledButton from './LabelledButton';

const FloatingResources = () => {
	
	const [open, setOpen] = useState(false);

	return(
		<>
			<Box
                sx={{
                    zIndex:9,
                    position:'absolute',
                    top:'4.5rem',
                    right:'1rem'
                }}
            >
                <LabelledButton
                    label='REPOSITORY'
                    icon={faBooks}
                    background='#F88F11'
                    color='#fff'
                    onClick={()=>setOpen(true)}
                />
            </Box>
			<ResourceDialog 
				open={open} 
				handleClose={()=>setOpen(false)}
			/>
		</>
	);
};

export default FloatingResources;
