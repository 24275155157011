import { join, slice } from 'lodash';

const addRoutesToAreas = areas => {

  //console.log(areas);

  //loop through all area and add path
  const allAreas = areas.data.reduce((acc, cur) => {
    //console.log(acc, cur)
    if (!cur?.attributes?.parent_area?.data?.id) {
      //console.log('no parent') //top level
      return [...acc, { ...cur, route:  `/` }];
    }

    let parentsHistory = [];

    const getNestedRoutes = area => {
      parentsHistory = [area.attributes.slug, ...parentsHistory];
      if (area?.attributes?.parent_area?.attributes?.slug === '') {
        return (parentsHistory = [...parentsHistory]);
      }

      //console.log(area?.attributes?.parent_area?.data?.id)
      if (area?.attributes?.parent_area?.data?.id) {
        const parent = (areas.data).find(({ id }) => id === area.attributes.parent_area.data.id);
        getNestedRoutes(parent);
      }
    };

    getNestedRoutes(cur);

    const route = join(parentsHistory, '/');
    let 
      parentRoute = '',
      parentBackground = '',
      parentTransitionOut = '';

     
      if (cur?.attributes?.parent_area?.data?.id) {
        
      const parent = (areas.data).find(({ id }) => id === cur?.attributes?.parent_area?.data?.id);
      const pathArray = route.split('/');

      parentRoute = `${slice(pathArray, 0, pathArray.length - 1).join('/')}`;
      parentBackground =  parent?.attributes?.background?.data;
      parentTransitionOut =  parent?.attributes?.transition_out?.data;

    }

    return [
      ...acc,
      {
        ...cur,
        route: `${route}`,
        parent_area: !cur?.attributes?.parent_area?.data?.id ? null : { 
          ...cur?.attributes?.parent_area?.data?.id, 
          route: parentRoute ,
          background: parentBackground,
          transition_out: parentTransitionOut
        }
      }
    ];

  }, [])

  //console.log(allAreas)
  return allAreas

};

export default addRoutesToAreas;
