import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";

export default function Header({ details }) {

	const xlDown = useMediaQuery((theme) => theme.breakpoints.down('xl'));

	return (
		<Box
			sx={{
				zIndex: 1001,
				position: 'absolute',
				top: xlDown ? "1em" : "2em",
				left: xlDown ? "1em" : "2em",
				maxWidth: '33vw'
			}}
		>
			<Box
				sx={{
					background:theme=>theme.palette.primary.main,  // '#55277e',
					p: '0.5rem 0.75rem'
				}}
			>
				<Typography
					sx={{
						fontWeight: 700,
						color: '#fff',
						fontSize: '0.9rem',
						textTransform: 'uppercase',
						textAlign: 'center'
					}}
				>
					{details?.title}
				</Typography>
			</Box>
			{Boolean(details?.description) &&
				<Box
					sx={{
						background: '#fff',
						p: '0.75rem 1rem'
					}}
				>
					<Typography
						sx={{
							fontWeight: 700,
							color: '#000',
							fontSize: '0.8rem',
							textTransform: 'uppercase',
							textAlign: 'center'
						}}
					>
						{details.description}
					</Typography>
				</Box>
			}
		</Box>
	);
}