import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import HotspotInfoHighlights from './Hotspot_info_highlights';
import HotspotInfoCard from './Hotspot_info_card';
import { Box } from '@mui/material';
import { SAVE_INFO_BOX } from '../store/FarmReducers';
import { FarmContext } from '../store/FarmContext';

const HotspotInfo = ({ hotspot }) => {

  //console.log(hotspot)

  const [info_open, setInfo_open] = useState(false);
  const hotspot_info = useMemo(() => hotspot.attributes.type[0], [hotspot.attributes.type]);
  const position_x = useMemo(() => hotspot.attributes.position_x, [hotspot.attributes.position_x]);
  const position_y = useMemo(() => hotspot.attributes.position_y, [hotspot.attributes.position_y]);
  const {
    infoBox,
    dispatchInfoBox
  } = useContext(FarmContext);


  const handleIcon = useCallback(() => {

    if (info_open) {
      dispatchInfoBox({
        type: SAVE_INFO_BOX,
        payload: null
      });
      setInfo_open(false)
    } else {

      dispatchInfoBox({
        type: SAVE_INFO_BOX,
        payload: {
          id: hotspot.id
        }
      });
      //if current infor box is already set
      if (infoBox) {
        setTimeout(() => {
          setInfo_open(true)
        }, [1000])
      } else {
        setInfo_open(true)
      }
    }
    // setInfo_open(prevState => {
    //   if (prevState) {
    //     console.log('close', hotspot.id)
    //   } else {
    //     console.log('open', hotspot.id)
    //   }
    //   return !prevState
    // })

  }, [infoBox, info_open, hotspot])

  useEffect(() => {

    if (infoBox && infoBox.id !== hotspot.id) {
      setInfo_open(false)
    }

  }, [infoBox, hotspot])

  //console.log(info_open)

    //retrun null if not published
    if (!hotspot?.attributes?.publishedAt) return null;
  

  return (
    <Box sx={{
      //zIndex: info_open ? 10 : 1 ,
      // position : 'relative'
    }}>
      {/*// ***************** CARD ******************/}

      <HotspotInfoCard
        info_open={info_open}
        setInfo_open={setInfo_open}
        hotspot_info={hotspot_info}
        position_x={position_x}
        handleIcon={handleIcon}
      />

      {/*// ***************** HIGHLIGHTS ******************/}
      <AnimatePresence>
        {hotspot_info?.highlights && Object.values(hotspot_info?.highlights).length && info_open && (
          <HotspotInfoHighlights
            hotspot_info={hotspot_info}
            position_x={position_x}
            position_y={position_y}
          />
        )}
      </AnimatePresence>
    </Box>
  );
};

export default HotspotInfo;
