import axios from 'axios';

export const axiosRequest = async ({ filters, method, endpoint, data, params, url }) => {

  try {
    if (filters) {
      const response = await axios({
        method,
        url: url
          ? `api/${url}/${endpoint}?filters${filters}`
          : `${process.env.REACT_APP_API}/api/${endpoint}?${filters}`,
        params
      })
      return response.data;
    }
    if (data) {
      const response = await axios({
        method,
        url: url
          ? `api/${url}/${endpoint}`
          : `${process.env.REACT_APP_API}/api/${endpoint}`,
        data
      });
      return response.data;
    }
    if (params) {
      const response = await axios({
        method,
        url: url
          ? `api/${url}/${endpoint}`
          : `${process.env.REACT_APP_API}/api/${endpoint}`,
        params
      });
      return response.data;
    }
  } catch (error) {
    console.log(error.message);
    throw new Error(error.message);
  }
};



// import axios from 'axios';

// export const axiosRequest = async ({ queryKey }) => {
//   const { method, endpoint, data, params, url } = queryKey[1];

//   try {
//     if (data) {
//       const response = await axios({
//         method,
//         url: url
//           ? `${url}/${endpoint}`
//           : `${process.env.REACT_APP_API}/${endpoint}`,
//         data
//       });
//       return response.data;
//     }
//     if (params) {
//       const response = await axios({
//         method,
//         url: url
//           ? `${url}/${endpoint}`
//           : `${process.env.REACT_APP_API}/${endpoint}`,
//         params
//       });
//       return response.data;
//     }
//   } catch (error) {
//     console.log(error.message);
//     throw new Error(error.message);
//   }
// };
