export const SAVE_INFO_BOX = 'SAVE_INFO_BOX';

export const FarmReducer = (state, action) => {
  switch (action.type) {
    case SAVE_INFO_BOX:
      return action.payload;
    default:
      return state;
  }
};
