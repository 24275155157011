// export const square = {
//   hidden: {
//     width: '9vw',
//     height: '7vw',
//     border: 0
//   },
//   visible: info_open => ({
//     border: info_open ? 0 : `solid 5px white`,
//     width: info_open ? '100%' : '9vw',
//     height: info_open ? '100%' : '7vw',
//     maxWidth: 700,
//     maxHeight: 400,
//     transition: {
//       duration: 1
//     }
//   })
// };

export const icon = {
  hidden: {
    scale: 0
  },
  visible: {
    scale: 1
  },
  hover: {
    scale: 1.3
  }
};

export const info_card = {
  hidden: {
    opacity: 1,
    width: '9vw',
    height: '7vw',
    background: 'transparent',
    border: 'solid 5px white',
    zIndex : 1,
    transition: {
      duration: 1,
      when: 'afterChildren'
    }
  },
  visible: {
    opacity: 1,
    width: 'auto',
    height: 'auto',
    background: 'white',
    border: 0,
    zIndex : 1006,
    transition: {
      // delay: 0.5,
      duration: 1,
      when: 'beforeChildren',
      staggerChildren: 0.5
    }
  }
};
export const info_highlights = {
  hidden: ({ position_y }) => {
    const base_style = {
      opacity: 0,
      scale: 0.9,
      zIndex : 1,
      transition: {
        duration: 0.5
      }
    };

    if (position_y > 40) return { ...base_style, bottom: 0 };
    return { ...base_style, top: 0 };
  },
  visible: ({ position_y }) => {
    const base_style = {
      opacity: 1,
      scale: 1,
      zIndex : 1005,
      transition: {
        delay: 3,
        duration: 1
      }
    };
    if (position_y > 40) {
      return { ...base_style, bottom: '100%' };
    }
    return { ...base_style, top: '100%' };
  }
};

export const card_image = {
  hidden: {
    scale: 1,
    opacity: 0,
    transition: {
      delay: 0.5,
      duration: 0.5
    }
  },
  visible: {
    scale: 1,
    opacity: 1
  }
};

export const card_text = {
  hidden: {
    opacity: 0,
    scale: 1,
    transition: {
      delay: 0.5,
      duration: 0.5
    }
  },
  visible: {
    scale: 1,
    opacity: 1
  }
};
