import { Box, Fade, Grid, useMediaQuery } from '@mui/material';
import React, { forwardRef, useContext, useState } from 'react';
import { useEffect } from 'react';
import { AreaContext } from '../store/AreaContext';
import { SAVE_CURRENT_AREA } from '../store/AreaReducers';
import AreaBackground from './AreaBackground';
import FloatingCustom from './Floating_custom';
import FloatingResources from './Floating_resources';
import Hotspot from './Hotspot';
import HotspotButton from './Hotspot_button';
import PortraitAlert from './PortraitAlert';
import Timeline from './Timeline';
import { isMobile } from 'react-device-detect';
import FilterBar from './FilterBar';
import LanguageSelect from './LanguageSelect';
import OverviewButtons from './OverviewButtons';
import Header from './Header';

const Area = forwardRef(({ area, video_ref, handleVideoSnapshot, canvas, firstLoad, setFirstLoad, fixed, portrait }, ref) => {

	const { transition, dispatchCurrentArea } = useContext(AreaContext);
	const [isVisible, setIsVisible] = useState();
	const xlDown = useMediaQuery((theme) => theme.breakpoints.down('xl'));

	useEffect(() => {

		dispatchCurrentArea({
			type: SAVE_CURRENT_AREA,
			payload: {
				background: area.attributes.background,
				route: area.route,
				slug: area.attributes.slug,
				transition_out: area.attributes.transition_out.data || null,
				floating_buttons: area.attributes.floating_button ? area.attributes.floating_button : [],
				parent_area: area.parent_area
					? {
						background: area.parent_area.background,  //custom added
						route: area.parent_area.route, //custom added
						slug: area.attributes.parent_area.data.attributes.slug,
						transition_out: area?.attributes?.parent_area.data?.attributes?.transition_out?.data?.attributes || null
					}
					: null
			}
		});
	}, [area, dispatchCurrentArea]);

	//when videos are playing hide other elements
	useEffect(() => {
		if (transition.play) {
			setIsVisible(false)
		} else {
			setIsVisible(true)
		}
	}, [transition])

	const splitLeft = (area) => {
		if (area.attributes.area_type[0].hotspot_left) {
			let hotspot = area.attributes.hotspots.data.find(hotspot => hotspot.id === area.attributes.area_type[0].hotspot_left.data.id)
			return (
				<Grid item xs={6} >
					<HotspotButton
						background={area?.attributes?.background?.data?.attributes.url}
						area={area.attributes.area_type[0]}
						hotspot={hotspot}
						video_ref={hotspot.attributes.type[0].transition_in.data.attributes}
						side="left"
						isVisible={isVisible}
						canvas={canvas}
					/>
				</Grid>
			)
		}
	}
	const splitRight = (area) => {
		if (area.attributes.area_type[0].hotspot_right) {
			let hotspot = area.attributes.hotspots.data.find(hotspot => hotspot.id === area.attributes.area_type[0].hotspot_right.data.id)
			return (
				<Grid item xs={6} >
					<HotspotButton
						background={area?.attributes?.background?.data?.attributes.url}
						area={area.attributes.area_type[0]}
						hotspot={hotspot}
						video_ref={hotspot.attributes.type[0].transition_in.data.attributes}
						side="rigth"
						isVisible={isVisible}
						canvas={canvas}
					/>
				</Grid>
			);
		}
	}

	return (
		<>
			<PortraitAlert portrait={portrait} />
			<Box
				sx={{
					position: 'relative',
					display: 'grid',
					width: '100%',
					alignItems: 'center',
					overflow:'clip'
				}}
			>
				<AreaBackground
					firstLoad={firstLoad}
					setFirstLoad={setFirstLoad}
					area={area}
					video_ref={video_ref}
					handleVideoSnapshot={handleVideoSnapshot}
					canvas={canvas}
				/>
				{/* Floating corner UI elements */}
				{!portrait && (
					<Fade in={isVisible}>
						<Box>
							{/* Top left */}
							<Header details={area.attributes.area_type[0]} />
							{/* Top right */}
							<FilterBar />
							<FloatingResources fixed={false} />
							{/* Bottom right */}
							<LanguageSelect />
							{/* Bottom left */}
							<OverviewButtons />
						</Box>
					</Fade>
				)}
				{area.attributes.Logo.data.attributes.url &&
					<Fade in={isVisible} timeout={{ enter: 1000, exit: 1000 }}>
						<Grid
							container
							direction="row"
							justifyContent="center"
							sx={{
								zIndex: 0,
								position: 'absolute',
								height: '300px',
								width: '100%',
								background: 'linear-gradient(to bottom, rgba(255,255,255,0.7) 20%,rgba(255,255,255,0) 100%)',
								top: 0,
								paddingTop: xlDown ? '1em' : '2em',
							}}
						>
							<Grid item >
								<img
									style={{
										width: isMobile ? '200px' : xlDown ? '300px' : '400px',
									}}
									alt="certis"
									src={`${process.env.REACT_APP_API}${area.attributes.Logo.data.attributes.url}`}
								/>
							</Grid>
						</Grid>
					</Fade>
				}
				{area.attributes.area_type[0].__component === 'area.slider' &&
					<Fade in={isVisible} timeout={{ enter: 1000, exit: 1000 }}>
						<Box sx={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							background: '#fff',
							transform: 'translate(-50%,-50%)',
							maxWidth: '700px'
						}}>
							<Grid item container >
								{splitLeft(area)}
								{splitRight(area)}
							</Grid>
						</Box>
					</Fade>
				}
				{//This is the timeline section 
					area.attributes.area_type[0].__component === 'area.timeline' &&
					<Fade in={isVisible} timeout={{ enter: 1000, exit: 1000 }}>
						<Box>
							<Timeline
								canvas={canvas}
								area={area}
							/>
						</Box>
					</Fade>
				}
				{/*If this section is split screen  don't show the hotspots*/}
				{(!portrait && !transition.play && area.attributes.area_type[0].__component !== 'area.slider') && area?.attributes?.hotspots &&
					area.attributes.hotspots.data.map(hotspot => {
						return (
							<Hotspot
								key={hotspot.id}
								hotspot={hotspot}
								video_ref={video_ref}
								area={area.attributes}
								canvas={canvas}// added so we can set Canvas on hotspot 
							/>
						)
					})}
				{
					area.attributes.floating_button && !portrait && (
						<FloatingCustom fixed={fixed} fabDetails={area.attributes.floating_button} />
					)
				}
			</Box >
		</>
	);
}
);

export default Area;
