export const SAVE_ALL_AREAS = 'SAVE_ALL_AREAS';
export const SAVE_CURRENT_AREA = 'SAVE_CURRENT_AREA';
export const SAVE_DESTINATION = 'SAVE_DESTINATION';
export const PLAY = 'PLAY';
export const STOP = 'STOP';
export const RESET = 'RESET';
export const FADE = 'FADE';

export const AreaReducer = (state, action) => {
  switch (action.type) {
    case SAVE_ALL_AREAS:
      return action.payload;
    case SAVE_CURRENT_AREA:
      return action.payload;
    case SAVE_DESTINATION:
      return action.payload;
    case PLAY:
      const { url, to } = action.payload;
      return {
        play: true,
        stop: false,
        url,
        to
      };
      case FADE:
        return {
          play: true,
          stop: false,
          url: null,
          to: action.payload
        };
    case STOP:
      return { play: false, stop: true, url: null, to: '/' };
    case RESET:
      return {
        playing: false,
        stop: true,
        to: '/',
        url: null
      };
    default:
      return state;
  }
};
