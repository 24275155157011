import {
  faDungeon,
  faLeaf,
  faWarehouseAlt,
  faWheat
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  DialogContent,
  Grid,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { lazy } from 'react';
import { fab_base_style } from '../styles/floating_button';
import { Dialog, Typography } from '@mui/material';
import { useState } from 'react';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import MapAreaDescription from './MapAreaDescription';

const useStyles = makeStyles(theme => ({
  open_button: {
    background: ({ color }) => color,
    position: ({ fixed }) => (fixed ? 'fixed' : 'absolute'),
    right: theme.spacing(4),
    bottom: theme.spacing(4) + theme.spacing(4) + theme.spacing(8),
    [theme.breakpoints.down('lg')]: {
      right: theme.spacing(3),
      bottom: theme.spacing(2) + theme.spacing(3) + theme.spacing(7)
    },
    [theme.breakpoints.down('md')]: {
      right: theme.spacing(2),
      bottom: theme.spacing(2) + theme.spacing(3) + theme.spacing(5.5)
    },
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(1) + theme.spacing(2) + theme.spacing(5.5)
    },
    '&:hover': {
      //* Color when hover need to be fixed
      // background: theme.palette.field_1.dark,
      filter: 'brightness(0.5)',
      cursor: 'pointer'
    }
  },
  dialog: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(250,250,250, 0.6)'
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(5),
      background: theme.palette.farm.main,
      '& #dialog_content': {
        position: 'relative',
        padding: theme.spacing(3),
        border: `3px solid ${theme.palette.common.white}`,
        color: 'white',
        '& .MuiIconButton-root': {
          position: 'absolute',
          top: -34,
          right: -34,
          color: theme.palette.map.main,
          '&:hover': {
            color: theme.palette.map.dark
          },
          '& svg': {
            fontSize: '2.5rem',
            '& .fa-secondary': {
              color: theme.palette.map.main,
              opacity: 1
            },
            '& .fa-primary': {
              color: 'white'
            }
          }
        }
      }
    }
  },
  title: {
    background: theme.palette.map.main,
    padding: theme.spacing(2),
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
    fontWeight: 400
  },
  subtitle: {
    fontWeight: 400
  }
}));

const FloatingCustom = ({ fixed, fabDetails }) => {
  const [icon, setIcon] = useState(null);
  // *************** STYLES ***************
  const classes = useStyles({ color: fabDetails.color, fixed });
  const fab_base = fab_base_style({ fixed });

  lazy(
    import('@fortawesome/pro-light-svg-icons').then(module => {
      setIcon(module[fabDetails.icon_name]);
    })
  );

  // *************** USE STATE ***************
  const [open, setOpen] = useState(false);

  // *************** FUNCTIONS ***************
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Avatar
        className={`${fab_base.root} ${classes.open_button}
        `}
        id="fab_area"
        onClick={handleOpen}
      >
        <FontAwesomeIcon icon={icon || faLeaf} size="lg" />
      </Avatar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="resources_dialog"
        className={classes.dialog}
        fullWidth
        maxWidth="xl"
      >
        <DialogContent>
          <Grid
            container
            alignItems="center"
            direction="column"
            id="dialog_content"
          >
            {/* CLOSE DIALOG BUTTON */}
            <IconButton
              aria-label="close_dialog"
              onClick={handleClose}
              col="resources"
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </IconButton>
            {/* FARM MAP CONTENT */}
            <Grid item container spacing={1}>
              {/* FIRST ROW HEADER */}
              <Grid item container>
                <Grid item xs={3}></Grid>
                <Grid
                  item
                  xs={6}
                  container
                  direction="column"
                  alignItems="center"
                >
                  {/* TITLE */}
                  <Grid item>
                    <Typography variant="h6" className={classes.title}>
                      Farm Map
                    </Typography>
                  </Grid>
                  {/* SUBTITLE */}
                  <Grid item>
                    <Typography variant="h6" className={classes.subtitle}>
                      Select an area to navigate around the farm
                    </Typography>
                  </Grid>
                </Grid>
                <MapAreaDescription
                  setOpen={setOpen}
                  xs={3}
                  icon={faWarehouseAlt}
                  title="The Laboratory"
                  color="farm"
                  slug="laboratory"
                />
              </Grid>
              {/* MAPS' FIELDS */}
              <Grid item container alignItems="center">
                {/* GREENHOUSE */}
                <MapAreaDescription
                  setOpen={setOpen}
                  icon={faDungeon}
                  title="The Glasshouse"
                  xs={3}
                  style={{ paddingRight: 16 }}
                  color="farm"
                  slug="glasshouse"
                />

                <Grid item xs={9} container direction="column-reverse">
                  {/* FIELD 1 */}
                  <MapAreaDescription
                    setOpen={setOpen}
                    icon={faWheat}
                    title="Field 1"
                    className="field_1"
                    color="field_1"
                    slug="field-1"
                  />
                  {/* FIELD 2 */}
                  <MapAreaDescription
                    setOpen={setOpen}
                    icon={faWheat}
                    title="Field 2"
                    className="field_2"
                    style={{ marginBottom: 16 }}
                    color="field_2"
                    slug="field-2"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FloatingCustom;
