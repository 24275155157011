

import {createTheme} from "@mui/material";

// const theme = createTheme({
//   overrides: {
//     MuiCssBaseline: {
//       '@global': {
//         body: {
//           overflow: 'hidden',
//           backgroundColor: 'transparent'
//         },
//         html: {
//           background: 'black'
//         }
//       }
//     }
//   },
//   palette: {
//     primary: {
//       main: '#008DD6'
//     },
//     success: {
//       main: '#009741'
//     },
//     resources: {
//       main: '#F88F11',
//       light: '#f9a643',
//       dark: '#d07406'
//     },
//     map: {
//       main: '#265EA9',
//       light: '#3175d1',
//       dark: '#1d477f'
//     },
//     farm: {
//       main: '#8FC23D',
//       light: '#a5ce64',
//       dark: '#729b31'
//     },
//     field_1: {
//       main: '#EDC48C',
//       light: '#f4dbb8',
//       dark: '	#e6ad60'
//     },
//     field_2: {
//       main: '#868D42',
//       light: '#a6af53',
//       dark: '#656a32'
//     }
//   },
//   typography: {
//     fontFamily: ['Open Sans'],
//     fontWeight : 300
//   }
// });
// theme.overrides = {
//   ...theme.overrides,
//   MuiCssBaseline: {
//     ...theme.overrides.MuiCssBaseline,
//     '@global': {
//       ...theme.overrides.MuiCssBaseline['@global'],
//       a: {
//         textDecoration: 'none',
//         fontWeight: 'bold',
//         color: '#000000'
//       },
//       'a, span': {
//         fontSize: '1rem',
//         [theme.breakpoints.down('lg')]: {
//           fontSize: '0.8rem'
//         },
//         [theme.breakpoints.down('md')]: {
//           fontSize: '0.7rem'
//         }
//       }
//     }
//   }
// };
// theme.typography = {
//   ...theme.typography,

//   body1: {
//     fontSize: '1rem',
//     [theme.breakpoints.down('lg')]: {
//       fontSize: '0.8rem'
//     },
//     [theme.breakpoints.down('md')]: {
//       fontSize: '0.7rem'
//     }
//   },
//   h5: {
//     fontWeight: 800,
//     textTransform: 'uppercase',
//     fontSize: '1.4rem',
//     [theme.breakpoints.down('lg')]: {
//       fontSize: '1.1rem'
//     },
//     [theme.breakpoints.down('md')]: {
//       fontSize: '0.9rem'
//     }
//   },
//   h6: {
//     fontWeight: 800,
//     textTransform: 'uppercase',
//     fontSize: '1.3rem',
//     [theme.breakpoints.down('lg')]: {
//       fontSize: '1rem'
//     },
//     [theme.breakpoints.down('md')]: {
//       fontSize: '0.8rem'
//     }
//   }
// };
// export default theme;


const theme = createTheme({

  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#008cba',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated from palette.primary.main
      contrastText: '#FFF'
    },
    secondary: {
      main: '#AAA',
      contrastText: '#FFF'
    },
    background: {
      default: '#EFEFEF',
      paper: '#FFF',
      highlight: '#EEE',
      header: '#dcdcdc',
      hover: '#dcdcdc24',
      dark: '#666'
    },
    resources: {
      main: '#F88F11',
      light: '#f9a643',
      dark: '#d07406'
    },
    map: {
      main: '#265EA9',
      light: '#3175d1',
      dark: '#1d477f'
    },
    farm: {
      main: '#8FC23D',
      light: '#a5ce64',
      dark: '#729b31'
    },
    field_1: {
      main: '#EDC48C',
      light: '#f4dbb8',
      dark: '	#e6ad60'
    },
    field_2: {
      main: '#868D42',
      light: '#a6af53',
      dark: '#656a32'
    }
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    button: {
      //fontSize: '1rem',
      textTransform: 'none'
    }
  },
});

export default theme;