import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilePdf,
  faPlayCircle,
  faImage,
  faLink,
  faFileCode
} from '@fortawesome/pro-light-svg-icons';

const CardTypeIcon = ({ type }) => (
  <FontAwesomeIcon
    icon={
      type.__component === 'resource.file'
        ? faFilePdf
        : type.__component === 'resource.image'
        ? faImage
        : type.__component === 'resource.video'
        ? faPlayCircle
        : type.__component === 'resource.link'
        ? faLink
        : type.__component === 'resource.content'
        ? faFileCode
        : null
    }
    size="2x"
    className="media_icon"
  />
);

export default CardTypeIcon;
