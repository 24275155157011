import React, { useReducer, createContext } from 'react';
import { FarmReducer } from './FarmReducers';

export const FarmContext = createContext();

export const FarmProvider = ({ children }) => {
  const [infoBox, dispatchInfoBox] = useReducer(FarmReducer, {});

  const contextValues = {
    infoBox,
    dispatchInfoBox,
  };
  return (
    <FarmContext.Provider value={contextValues}>
      {children}
    </FarmContext.Provider>
  );
};
