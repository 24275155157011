import { faHomeAlt, faSeedling } from "@fortawesome/pro-regular-svg-icons";
import { Box } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import LabelledButton from "./LabelledButton";

export default function OverviewButtons(){

    const history=useHistory();
    const location=useLocation();

    return(
        <Box
            sx={{
                position:'absolute',
                bottom:'1rem',
                left:'1rem',
                display:'flex',
                flexDirection:'column',
                gap:'1rem'
            }}
        >
            <Box
                sx={{
                    display: location.pathname.split('/').length>=3? 'block' : 'none'
                }}
            >
                <LabelledButton
                    direction='right'
                    label='CROP OVERVIEW'
                    icon={faSeedling}
                    background='rgb(145, 215, 10)'
                    color='#fff'
                    onClick={()=>{
                        const segments=location.pathname.split('/');
                        history.push('/'+segments[1]);
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: location.pathname==='/'? 'none' : 'block'
                }}
            >
                <LabelledButton
                    direction='right'
                    label='FARM OVERVIEW'
                    icon={faHomeAlt}
                    background='rgb(0, 160, 195)'
                    color='#fff'
                    onClick={()=>history.push('/')}
                />
            </Box>
        </Box>
    );
}