

import React, {  useEffect } from "react";

const MainCanvas = ({ canvas_ref }) => {

    useEffect(() => {

        if (canvas_ref.current ) {

            const canvas = canvas_ref.current;
            //const context = canvas.getContext("2d");
            const aspectRatio = 1920 / 1080;
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            const windowAspectRatio = windowWidth / windowHeight;
            
            let imageWidth, imageHeight
            if (windowAspectRatio < aspectRatio) {
                imageWidth = windowWidth;
                imageHeight = windowWidth / aspectRatio;
            } else {
                imageWidth = windowHeight * aspectRatio;
                imageHeight = windowHeight;
            }
            canvas.width = imageWidth
            canvas.height = imageHeight

            window.addEventListener("resize", function () {

                const windowWidth = window.innerWidth;
                const windowHeight = window.innerHeight;
                const windowAspectRatio = windowWidth / windowHeight;

                let imageWidth, imageHeight
                if (windowAspectRatio < aspectRatio) {
                    imageWidth = windowWidth;
                    imageHeight = windowWidth / aspectRatio;
                } else {
                    imageWidth = windowHeight * aspectRatio;
                    imageHeight = windowHeight;
                }
                canvas.width = imageWidth
                canvas.height = imageHeight
            
            });

            return () => {
                window.removeEventListener("resize", function () { });
            };

        }
    }, [canvas_ref.current]);

    return (
        <canvas ref={canvas_ref} id="main-canvas"></canvas>
    );
};

export default MainCanvas;
