import { makeStyles } from '@mui/styles';

export const fab_base_style = makeStyles(theme => ({
  root: ({ fixed }) => ({
    zIndex: theme.zIndex.speedDial,
    //position: fixed ? 'fixed' : 'absolute',
    position: 'absolute',
    width: theme.spacing(10),
    height: theme.spacing(10),
    borderRadius: 100,
    ' & .svg-inline--fa': {
      fontSize: '2rem',
      color: 'white',
      [theme.breakpoints.down('lg')]: {
        fontSize: '1.6rem'
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '1.3rem'
      }
    },
    [theme.breakpoints.down('lg')]: {
      width: theme.spacing(7),
      height: theme.spacing(7)
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(5.5),
      height: theme.spacing(5.5)
    }
  })
}));

export const bottom_right_style = makeStyles(theme => {
  return {
    root: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      [theme.breakpoints.down('lg')]: {
        bottom: theme.spacing(1),
        right: theme.spacing(1)
      },
      
    }
  };
});

export const top_right_style = makeStyles(theme => {
  return {
    root: {
      top: theme.spacing(4),
      right: theme.spacing(4),
      [theme.breakpoints.down('lg')]: {
        top: theme.spacing(3),
        right: theme.spacing(3)
      },
      [theme.breakpoints.down('md')]: {
        top: theme.spacing(2),
        right: theme.spacing(2)
      }
    }
  };
});
