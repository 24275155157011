import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CircularProgress,
  Grid,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { axiosRequest } from '../functions/axiosRequest';
import { AreaContext } from '../store/AreaContext';
import { PLAY, SAVE_DESTINATION } from '../store/AreaReducers';

const useStyles = makeStyles(theme => ({
  field: ({ color }) => ({
    minHeight: 200,
    position: 'relative',
    height: '100%',
    background: theme.palette[color].main,
    '&:hover': {
      cursor: 'pointer'
    }
  }),
  dropShadow: ({ color }) => ({
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: theme.palette[color].dark
  }),
  area_description: {
    zIndex: theme.zIndex.appBar
  },
  title: ({ color }) => ({
    background: theme.palette[color].dark,
    padding: theme.spacing(1),
    color: theme.palette.common.white,
    textTransform: 'uppercase'
  })
}));

const MapAreaDescription = ({
  icon,
  title,
  childGridProps,
  className,
  color,
  slug,
  setOpen,
  ...props
}) => {
  //const history = useHistory();
  const classes = useStyles({ color });
  const { allAreas, dispatchTransition, currentArea, dispatchDestination } = useContext(AreaContext);
  const [isHover, setIsHover] = useState(false);
  //const [hotspot, setHotspot] = useState(null);

  const findRoute = (from, to) =>{
    //console.log(from,to)
    let fromArry = from.split('/')
    let toArry = to.split('/')
    //console.log(fromArry,toArry)
    let newFrom = fromArry.filter(val => !toArry.includes(val)).reverse();
    let newTo = toArry
    if(fromArry[1] === toArry[1] ){
      newTo.splice(0,1)
    }
    newFrom = newFrom.map((item,i)=>{
      return {
        'transition' : 'out',
        'slug' : item
      }
    })
    newTo = newTo.map((item,i)=> {
      if(i === 0 ){
        return {
          'transition' : 'none',
          'slug' : item
        }
      } else {
        return {
          'transition' : 'in',
          'slug' : item
        }
      }
    })
    //console.log(newFrom)
    //console.log(newTo)
    let fullRoute = newFrom.concat(newTo);
    
    let final = fullRoute.map(area => {
      let found = allAreas.find(match => match.slug === area.slug)
      //console.log(found)
      if(found){
      if(area.transition === 'out'){
        return {
          ...area,
          'transtions_video_url' : found.transition_out.url
        } 
      } else if(area.transition === 'in'){
        //find hotspot from parent
        //console.log(found)
        let parent = allAreas.find(match => match.id === found.parent_area.id)
        
        if(parent && Object.values(parent.hotspots).length){
          let hotspot = parent.hotspots.find(hot => hot.slug === found.slug)
          return {
            ...area,
            'transtions_video_url' : hotspot.type[0].transition_in.url
          } 
        } 
      } else if(area.transition === 'none'){
        return {
          ...area
        } 
      }
    }
       
    });
   return final
  }

    // back 1 level
    //findRoute('/growing-tunnel/natural-enemies','/growing-tunnel');
    //back 1 - foward 1
    //findRoute('/growing-tunnel/natural-enemies','/growing-tunnel/efficacy');
    //new top level route
    //findRoute('/growing-tunnel/natural-enemies','/glasshouse/natural-enemies-glasshouse');
    
  // useEffect(() => {

  //   console.log(allAreas,currentArea)
  //   //create array of all vidoe transtions
  //   console.log(currentArea.route)
  //   console.log(slug)
   
  //   // if(data && Object.values(data).length){
  //   //   setHotspot({
  //   //     ...data[0],
  //   //     route: `/${data[0].area.slug}/${data[0].slug}`
  //   //   });
  //   // }
  // }, [allAreas,currentArea,slug]);

  const handleClick = () => {

    let videos = findRoute(currentArea.route, slug)

     console.log(videos)
  //   if(hotspot){
  //   setOpen(false);
  //   const transition_in_url = hotspot.type[0].transition_in.url;
  //   // Save the destination area
  //   dispatchDestination({
  //     type: SAVE_DESTINATION,
  //     payload: {
  //       to: hotspot.route,
  //       transition_url: transition_in_url,
  //       area: hotspot.area
  //     }
  //   });
  //   dispatchTransition({
  //     type: PLAY,
  //     payload: {
  //       url: currentArea.transition_out.url,
  //       to: currentArea.parent_area
  //         ? currentArea.parent_area.route
  //         : hotspot.route
  //     }
  //   });
  //   //! BUG FIX: This solve the problem where transition out video is played twice when navigate with the map
  //   if (currentArea.parent_area) {
  //     history.push(currentArea.parent_area.route);
  //   }
  //  }
  };
  return (
    <Grid
      item
      className={`${classes.field} ${className}`}
      container
      justify="center"
      alignItems="center"
      onClick={handleClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      {...props}
    >
      <AnimatePresence>
        {isHover && (
          <motion.div
            className={classes.dropShadow}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0 }}
          />
        )}
      </AnimatePresence>
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.area_description}
          {...childGridProps}
        >
          <Grid item>
            <FontAwesomeIcon icon={icon} size="5x" />
          </Grid>
          <Grid item>
            <Typography className={`${classes.title} title`}>
              {title}
            </Typography>
          </Grid>
        </Grid>
    </Grid>
  );
};

export default MapAreaDescription;
